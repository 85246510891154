import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { patch, updateItem, removeItem, append } from '@ngxs/store/operators';
import {
  CloseSession,
  CreateSession,
  DeclineInvitation,
  FetchInvitation,
  RefreshSession,
  SetActiveSession,
  JoinSession,
} from './remote-sessions.actions';
import { RemoteSessionsApi } from './remote-sessions.api';
import { ClosSession, ClosSessionInvitation } from './types';
import { SessionStatsResolver } from './session-stats';

export interface RemoteSessionsStateModel {
  sessions: ClosSession[];
  invitations: ClosSessionInvitation[];
}

@State<RemoteSessionsStateModel>({
  name: 'remoteSessions',
  defaults: {
    sessions: [],
    invitations: [],
  },
})
@Injectable()
export class RemoteSessionsState {
  static sessions = createSelector(
    [RemoteSessionsState],
    ({ sessions }: RemoteSessionsStateModel) => sessions,
  );

  static invitations = createSelector(
    [RemoteSessionsState],
    ({ invitations }: RemoteSessionsStateModel) => invitations,
  );

  static sessionById = (sessionId: string) => {
    return createSelector([RemoteSessionsState.sessions], (sessions: ClosSession[]) => {
      return sessions.find(({ id }) => sessionId === id) || null;
    });
  };

  static hasSession = (sessionId: string) => {
    return createSelector(
      [RemoteSessionsState.sessionById(sessionId)],
      (session: ClosSession | null) => !!session,
    );
  };

  static sessionStats = (sessionId: string) => {
    return createSelector(
      [RemoteSessionsState.sessionById(sessionId)],
      (session: ClosSession | null) => {
        if (!session) {
          return null;
        }

        return new SessionStatsResolver(session).resolve();
      },
    );
  };

  static invitationBySessionId = (sessionId: string) => {
    return createSelector(
      [RemoteSessionsState.invitations],
      (invitations: ClosSessionInvitation[]) => {
        return invitations.find(({ session: { id } }) => sessionId === id) || null;
      },
    );
  };

  // region Deprecated selectors
  /* eslint-disable @typescript-eslint/member-ordering */
  /** @deprecated - use {@link RemoteSessionsState.hasSession} instead */
  static hasActiveSession = createSelector(
    [RemoteSessionsState],
    ({ sessions }: RemoteSessionsStateModel) => sessions.length > 0,
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionById} instead */
  static activeSession = createSelector(
    [RemoteSessionsState],
    ({ sessions: [currentActiveSession] }: RemoteSessionsStateModel) => {
      return currentActiveSession || null;
    },
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static isAllActiveRolesTaken = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => {
      if (!activeSession) {
        return false;
      }

      return (
        !!activeSession.photographerId &&
        !!activeSession.modelId &&
        !!activeSession.observers.length
      );
    },
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static activeRoleTakenByUserIds = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => {
      if (!activeSession) {
        return [];
      }

      return SessionStatsResolver.activeRolesState(activeSession).users;
    },
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static photographerExist = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => !!activeSession?.photographerId,
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static modelExist = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => !!activeSession?.modelId,
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionById} to extract the observers */
  static observers = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => activeSession?.observers || [],
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static observerExist = createSelector(
    [RemoteSessionsState.observers],
    (observers: string[]) => observers.length > 0,
  );

  /** @deprecated - use {@link RemoteSessionsState.sessionStats} instead */
  static countOfParticipants = createSelector(
    [RemoteSessionsState.activeSession],
    (activeSession: ClosSession | null) => {
      if (!activeSession) {
        return 0;
      }

      return SessionStatsResolver.participantsCount(activeSession);
    },
  );
  /* eslint-enable @typescript-eslint/member-ordering */
  // endregion

  constructor(private api: RemoteSessionsApi) {}

  @Action(SetActiveSession)
  protected onSetActiveSession(
    { patchState }: StateContext<RemoteSessionsStateModel>,
    { payload }: SetActiveSession,
  ): void {
    patchState({ sessions: [payload] });
  }

  @Action(CreateSession)
  protected onCreateSession(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: CreateSession,
  ) {
    return this.api.createSession(payload).pipe(
      tap((session) => {
        setState(
          patch({
            sessions: append([session]),
          }),
        );
      }),
    );
  }

  @Action(JoinSession)
  protected onJoinSession(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: JoinSession,
  ) {
    return this.api.joinSession(payload).pipe(
      tap((session) => {
        setState(
          patch({
            sessions: append([session]),
            invitations: removeItem<ClosSessionInvitation>(
              (invitation) => invitation.session.id === session.id,
            ),
          }),
        );
      }),
    );
  }

  @Action(CloseSession)
  protected onCloseActiveSession(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: CloseSession,
  ): void {
    setState(
      patch({
        sessions: removeItem<ClosSession>(({ id }) => id === payload.session.id),
      }),
    );
  }

  @Action(RefreshSession, {
    cancelUncompleted: true,
  })
  protected onRefreshSession(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: RefreshSession,
  ) {
    return this.api.getSession({ sessionId: payload.session.id }).pipe(
      tap((update) => {
        const sessions = updateItem<ClosSession>(
          (session) => session.id === payload.session.id,
          (current) => ({
            ...current,
            ...update,
          }),
        );

        setState(patch({ sessions }));
      }),
    );
  }

  @Action(FetchInvitation)
  protected onFetchInvitation(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: FetchInvitation,
  ) {
    return this.api.getSession({ sessionId: payload.session.id }).pipe(
      tap((session) => {
        setState(
          patch({
            invitations: append<ClosSessionInvitation>([{ session }]),
          }),
        );
      }),
    );
  }

  @Action(DeclineInvitation)
  protected onDeleteInvitation(
    { setState }: StateContext<RemoteSessionsStateModel>,
    { payload }: FetchInvitation,
  ) {
    return setState(
      patch({
        invitations: removeItem<ClosSessionInvitation>((invitation) => {
          return invitation.session.id === payload.session.id;
        }),
      }),
    );
  }
}
