import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LogoutEffect } from './effects/logout-effect';
import { TokenRefreshEffect } from './effects/token-refresh-effect';
import { SessionInviteEffect } from './effects/session-invite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LogoutEffect, TokenRefreshEffect, SessionInviteEffect],
})
export class AppComponent implements OnInit {
  constructor(
    protected logoutEffect: LogoutEffect,
    protected tokenRefreshEffect: TokenRefreshEffect,
    protected sessionInviteEffect: SessionInviteEffect,
  ) {}

  ngOnInit() {
    this.logoutEffect.use();
    this.tokenRefreshEffect.use();
    this.sessionInviteEffect.use();
  }
}
