import { TrackableAction } from '@apps/remote-sessions/state/processing';
import { ClosSession, ClosSessionRole } from './types';

export class CreateSession implements TrackableAction {
  static readonly type = '[Remote Sessions] Create Session';

  readonly track = true;

  constructor(
    readonly payload: {
      useCloud: boolean;
      public: boolean;
      name: string;
      role: ClosSessionRole;
      /** ISO string */
      date: string;
    },
  ) {}
}

export class JoinSession implements TrackableAction {
  static readonly type = '[Remote Sessions] Join Session';

  readonly track = true;

  constructor(
    readonly payload: {
      sessionId: string;
      role: ClosSessionRole;
    },
  ) {}
}

export class RefreshSession implements TrackableAction {
  static readonly type = '[Remote Sessions] Refresh Session';

  readonly track = true;

  constructor(readonly payload: { session: { id: string } }) {}
}

export class CloseSession {
  static readonly type = '[Remote Sessions] Close Active Session';

  constructor(readonly payload: { session: { id: string } }) {}
}

export class FetchInvitation implements TrackableAction {
  static readonly type = '[Remote Sessions] Fetch Invitation';

  readonly track = true;

  constructor(readonly payload: { session: { id: string } }) {}
}

export class DeclineInvitation {
  static readonly type = '[Remote Sessions] Decline Invitation';

  constructor(readonly payload: { session: { id: string } }) {}
}

/** @deprecated - use {@link FetchInvitation} instead */
export class SetActiveSession {
  static readonly type = '[Remote Sessions] Set Active Session';

  constructor(public payload: ClosSession) {}
}
