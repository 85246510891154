import { ClosSession } from '@apps/remote-sessions/state/remote-sessions/types';

export interface ClosSessionDTO {
  _id: string;

  name: string;

  /** Sessions are always of type "standard" */
  type: 'standard';

  owner_id: string;

  owner_name: string;

  /** Empty string if the role hasn't been filled yet */
  photograph_id: string;

  /** Empty string if the role hasn't been filled yet */
  model_id: string;

  /** Consists of observers IDs */
  participants: string[];

  status: string;

  /** It's always empty string during update phase (GET /v1/room/{roomId}) */
  token: string;

  links_by_role: {
    photograph?: string;
    model?: string;
    observer: string;
  };

  link: string;

  /** @deprecated - use {@link ClosSessionDTO.link} instead */
  deeplink: string;

  is_cloud_images: boolean;

  is_available_for_all: boolean;

  clos_cloud_images_count: number;

  dropbox_images_count: number;

  local_images_count: number;

  inner_gallery_images_count: number;

  is_paid_owner: boolean;

  avatars: { [participantId: string]: string } | null;

  users: { [participantId: string]: ClosSessionParticipantDTO } | null;
}

export enum ClosSessionRoleDTO {
  observer = 'observer',
  model = 'model',
  photographer = 'photograph',
}

export interface ClosSessionParticipantDTO {
  _id: string;

  name: string;

  /** Contains avatar url or an empty string if the user doesn't have their pic */
  avatar: string;
}

export interface CreateSessionInput {
  useCloud: boolean;

  public: boolean;

  name: string;

  role: ClosSessionRoleDTO;

  /** ISO string */
  date: string;
}

export interface JoinSessionInput {
  sessionId: string;

  role: ClosSessionRoleDTO;
}

export interface GetSessionInput {
  sessionId: string;
}

export type GetSessionOutput = Omit<ClosSession, 'roomToken'>;
