import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ClosSession, ClosStorage } from './types';
import {
  ClosSessionDTO,
  CreateSessionInput,
  GetSessionInput,
  GetSessionOutput,
  JoinSessionInput,
} from './types.dto';

@Injectable({
  providedIn: 'root',
})
export class RemoteSessionsApi {
  protected baseUrl = `${environment.api.baseUrl}/v1/room`;

  constructor(protected http: HttpClient) {}

  createSession(input: CreateSessionInput): Observable<ClosSession> {
    return this.http
      .post<{ data: ClosSessionDTO }>(this.baseUrl, {
        role: input.role,
        is_cloud_images: input.useCloud,
        is_available_for_all: input.public,
        name: input.name,
        type: 'standard',
      })
      .pipe(
        map(({ data }) => data),
        map(RemoteSessionsApi.toClosSession),
      );
  }

  joinSession(input: JoinSessionInput): Observable<ClosSession> {
    return this.http
      .post<{
        data: ClosSessionDTO;
      }>(`${this.baseUrl}/${input.sessionId}/join`, { role: input.role })
      .pipe(
        map(({ data }) => data),
        map(RemoteSessionsApi.toClosSession),
      );
  }

  getSession({ sessionId }: GetSessionInput): Observable<GetSessionOutput> {
    return this.http.get<{ data: ClosSessionDTO }>(`${this.baseUrl}/${sessionId}`).pipe(
      map(({ data }) => data),
      map(RemoteSessionsApi.toClosSession),
      map((session) => {
        // uglyhack
        // roomToken is always undefined,
        // so it must be removed from the session object
        const { roomToken: _, ...rest } = session;

        return { ...rest };
      }),
    );
  }

  protected static toClosSession(dto: ClosSessionDTO): ClosSession {
    return {
      id: dto._id,
      name: dto.name,
      roomToken: dto.token,
      ownerId: dto.owner_id,
      ownerName: dto.owner_name,
      photographerId: dto.photograph_id,
      modelId: dto.model_id,
      observers: dto.participants,
      link: dto.link,
      filesStorageType: dto.is_cloud_images ? ClosStorage.cloud : ClosStorage.device,
      deepLink: dto.deeplink,
      is_cloud_images: dto.is_cloud_images,
    };
  }
}
