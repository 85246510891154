import { ClosSession, ClosSessionRole } from './types';

export interface SessionStats {
  participantsCount: number;

  activeRolesState: ActiveRolesState;

  hasObservers: boolean;

  /** List of users IDs that have joined to the session - both active roles and observers */
  allUsers: string[];
}

/** Shortcut for the details based on {@link ActiveRoles}  */
export interface ActiveRolesState {
  /** `true` - if available and may be taken */
  available: ActiveRolesOccupation;

  /** `true` - if someone has already taken the role */
  used: ActiveRolesOccupation;

  hasAvailableActiveRoles: boolean;

  /** List of users IDs that have taken active roles */
  users: string[];
}

/** Roles in the {@link ClosSession} that may control the camera and interact with its settings */
export type ActiveRoles = Exclude<ClosSessionRole, ClosSessionRole.observer>;

export type ActiveRolesOccupation = Record<ActiveRoles, boolean>;

export class SessionStatsResolver {
  constructor(protected session: ClosSession) {}

  resolve(): SessionStats {
    return {
      participantsCount: SessionStatsResolver.participantsCount(this.session),
      activeRolesState: SessionStatsResolver.activeRolesState(this.session),
      allUsers: SessionStatsResolver.usersList(this.session),
      hasObservers: this.session.observers.length > 0,
    };
  }

  static participantsCount(session: ClosSession): number {
    const { photographerId, modelId, observers } = session;
    const userIds = [photographerId, modelId, ...observers].filter(Boolean);

    return userIds.length;
  }

  static activeRolesState({ photographerId, modelId }: ClosSession): ActiveRolesState {
    const users = [photographerId, modelId].filter(Boolean);
    const used = {
      [ClosSessionRole.photographer]: !!photographerId,
      [ClosSessionRole.model]: !!modelId,
    };
    const available = {
      photograph: !used.photograph,
      model: !used.model,
    };

    return {
      used,
      users,
      available,
      hasAvailableActiveRoles: Object.values(available).filter(Boolean).length > 0,
    };
  }

  static usersList({ photographerId, modelId, observers }: ClosSession): string[] {
    return [photographerId, modelId, ...observers].filter(Boolean);
  }
}
