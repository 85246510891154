import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PopupService } from '@shared/popup/services/popup.service';
import type { JoiningRoomModalResult } from '@shared/modules/joining-room-modal';
import { JoiningRoomModalInputs } from './types';

@Injectable({
  providedIn: 'root',
})
export class JoiningRoomModalService {
  constructor(protected popupService: PopupService) {}

  open(inputs: JoiningRoomModalInputs): Observable<JoiningRoomModalResult | void> {
    return from(import('@shared/modules/joining-room-modal')).pipe(
      switchMap(({ JoiningRoomModalComponent }) =>
        this.popupService.open({
          inputs,
          id: 'creating-room-modal',
          wrapped: false,
          type: JoiningRoomModalComponent,
        }),
      ),
    );
  }
}
