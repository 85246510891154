import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest, Observable, from } from 'rxjs';
import { distinctUntilChanged, switchMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  ClosSessionInvitation,
  ClosSessionRole,
  DeclineInvitation,
  FetchInvitation,
  JoinSession,
  RemoteSessionsState,
} from '@apps/remote-sessions/state/remote-sessions';
import {
  PatchUserPreferences,
  UserPreferencesState,
} from '@apps/remote-sessions/state/user-preferences';
import { JoiningRoomModalService } from '@apps/remote-sessions/services/joining-room-modal';
import { AuthState } from '@core/auth/state';
import { environment } from '@env/environment';
import { BranchSdkParsedData } from './types';

@Injectable()
export class SessionInviteEffect implements OnDestroy {
  protected destroy$ = new Subject<void>();

  constructor(
    protected store: Store,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected actions$: Actions,
    protected joiningRoomModal: JoiningRoomModalService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  use(): void {
    this.initOngoingSessionInvitationsHandling();
    this.initInvitationCreation();
    this.initSessionJoiningRedirections();
    this.initInvitaionDecliningHandling();
  }

  protected initInvitationCreation(): void {
    combineLatest([
      this.activatedRoute.queryParamMap.pipe(
        map((params) => params.get('_branch_match_id')),
        filter(Boolean),
        distinctUntilChanged(),
      ),
      this.store.select(AuthState.isSignedIn).pipe(filter(Boolean)),
    ])
      .pipe(
        switchMap(([sessionHashedInvitationId]) => {
          return this.parseHashedInvitation(sessionHashedInvitationId);
        }),
        tap(({ sessionId: id }) => {
          this.store.dispatch(
            new FetchInvitation({
              session: { id },
            }),
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected initOngoingSessionInvitationsHandling(): void {
    this.store
      .select(RemoteSessionsState.invitations)
      .pipe(
        filter((invitations) => invitations.length > 0),
        map(([ongoing]) => ongoing),
        distinctUntilChanged(),
        switchMap((invitation) => this.handleInvitation(invitation)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected initSessionJoiningRedirections(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(JoinSession),
        switchMap(() => this.router.navigate([`rooms/room`])),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected initInvitaionDecliningHandling(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(DeclineInvitation),
        switchMap(() => {
          return this.router.navigate([], {
            queryParams: {
              _branch_match_id: null,
              _branch_referrer: null,
            },
            queryParamsHandling: 'merge',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected handleInvitation(invitation: ClosSessionInvitation) {
    const existingRole = this.getUserRole(invitation);
    const { session } = invitation;

    if (existingRole) {
      return this.store.dispatch(new JoinSession({ sessionId: session.id, role: existingRole }));
    }

    const preferable = this.store.selectSnapshot(UserPreferencesState.preferableClosSessionConfig);

    return this.joiningRoomModal
      .open({
        invitation,
        preferableRole: preferable.role,
      })
      .pipe(
        switchMap((result) => {
          if (!result || !result.role) {
            return this.store.dispatch(new DeclineInvitation({ session }));
          }

          return this.store.dispatch([
            new JoinSession({ sessionId: session.id, role: result.role }),
            new PatchUserPreferences({
              preferableClosSessionConfig: {
                ...(preferable || {}),
                role: result.role,
              },
            }),
          ]);
        }),
      );
  }

  protected getUserRole(
    { session }: ClosSessionInvitation,
    user = this.store.selectSnapshot(AuthState.user),
  ): ClosSessionRole | null {
    if (!user) {
      throw new Error(`[Target user must be provided]`);
    }

    if (session.photographerId === user.id) {
      return ClosSessionRole.photographer;
    }

    if (session.modelId === user.id) {
      return ClosSessionRole.model;
    }

    return session.observers.includes(user.id) ? ClosSessionRole.observer : null;
  }

  protected parseHashedInvitation(id: string): Observable<{ sessionId: string }> {
    return from(import('branch-sdk')).pipe(
      // techdebt https://github.com/Unoporoduction/CLOS_Frontend/issues/496
      // uglyhack
      // the "branch-sdk" package reassigns an export in its module at some point
      // leading to "TypeError: Cannot set property which has only a getter" error in runtime
      // [it's only assumption] Since the whole webpack module is immutable
      // it doesn't allow to perform the operation. In order to solve this,
      // the following module is additionally destructed
      map((module) => ({ ...module })),
      switchMap((BranchApi) => {
        return new Observable<BranchSdkParsedData>((subscriber) => {
          BranchApi.init(environment.branchIo.apiKey, { branch_match_id: id }, (err, result) => {
            if (err) {
              subscriber.error(err);
            } else {
              subscriber.next(result.data_parsed as BranchSdkParsedData);
            }

            subscriber.complete();
          });
        });
      }),
      map((parsed) => ({ sessionId: parsed._id })),
    );
  }
}
