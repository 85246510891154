export { ClosSessionRoleDTO as ClosSessionRole } from './types.dto';

export interface ClosSession {
  id: string;

  name: string;

  /**
   * Access token for {@link [Twilio-Room](https://sdk.twilio.com/js/video/releases/2.29.0/docs/Room.html)}
   */
  roomToken: string;

  ownerId: string;

  ownerName: string;

  photographerId: string;

  modelId: string;

  observers: string[];

  link: string;

  filesStorageType: ClosStorage;

  /** @deprecated - use {@link ClosSession.link} instead */
  deepLink: string;

  /**
   * If the files are stored in the cloud, the property is `true`
   * @deprecated - use {@link ClosSession.filesStorageType}
   */
  is_cloud_images: boolean;
}

/**
 * After navigating by brunch io {@link ClosSession.link},
 * we should perform the following inviting flow:
 * 1. Find out more about the remote session that user joining to;
 * 2. If the user not a participant yet - they should select a role;
 * 3. Join the user to the session;
 * Each step may be declined by user or interrupted at some point,
 * so the invitation won't _become_ a session for the given user
 *
 * Though the pure {@link ClosSession} object fits the concept,
 * a new interface was introduced for two reasons:
 * 1. To emphasize the distinction between session and invitation,
 *    they should be considered as two different entities;
 * 2. The invitation model may be extended in future and populated with statuses, specified roles, etc.
 *
 * @remarks: The concept of invitations doesn't exist in API layer or business terms,
 * it's a pure "Remote Sessions" application frontend thing
 */
export interface ClosSessionInvitation {
  session: Omit<ClosSession, 'roomToken'>;
}

export enum ClosStorage {
  cloud = 'cloud',
  device = 'device',
}

/**
 * @deprecated - use {@link ClosSession} instead to avoid name collisions
 */
export type Room = ClosSession;
